/* Colors: Custom */
//Example: $cc-[NAME]: #......

// Leieoveroever colors
$cc-green: #1D332F !default;
$cc-gold: #A97B63 !default;
$cc-grey: #E1E1E4 !default;

// Other colors
$cc-white: #E1E1E4;
$cc-lightestGrey: #f0f0f0 !default;
$cc-lightGrey: #F4F4F4 !default;
$cc-grey: #B9B9B9 !default;
$cc-darkGrey: #888888 !default;
$cc-darkerGrey: #8B8B8B !default;
$cc-darkestGrey: #3B3B3B !default;
$cc-black:#293241 !default;
$cc-blackShade: #435572 !default;
$cc-blue: #E8BB69 !default;
$cc-yellow: #E8BB69 !default;
$cc-yellowShade: #c59f59 !default;