/* Logo */
.Logo {

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        display: none;
    } 

    &__mobile {
        display: none;
        
        svg {
            width: auto;
            height: 40px;
        }

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            display: block;
        } 
    }

}

