.ContentBlock {
    padding-top: 270px;
    padding-bottom: 230px;

    @include mq($from: 1921px, $media-type: $mq-type, $mq_debug: null) {
        max-width: 1920px;
        margin: 0 auto 0;
    }

    @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
        padding-top: 100px;
        padding-bottom: 50px;
    } 

    &--container {
        position: relative;
        padding-top: 270px;
        padding-bottom: 200px;
        display: flex;

        @include mq($from: 1921px, $media-type: $mq-type, $mq_debug: null) {
            max-width: 1920px;
            margin: 0 auto;
        } 

        @include mq($from: 1024px, $until: 1320px, $media-type: $mq-type, $mq_debug: null) {
            padding-top: 460px;
        } 

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            padding-top: 0px;
            padding-bottom: 50px;
            flex-direction: column;
        } 

        .ContentBlock-content {
            margin-left: 160px;

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                margin-left: 20px;
                margin-right: 20px;
            } 

            &__text {
                margin-left: 0;
                max-width: 540px;
            }
        }
    }

    &__extra {
        position: absolute;
        top: -70px;
        left: 965px;
        font-weight: 400;
        font-family: $font-arboria;

        @include mq($from: 1321px, $until: 1620px, $media-type: $mq-type, $mq_debug: null) {
            left: 850px;
        } 

        @include mq($from: 1024px, $until: 1320px, $media-type: $mq-type, $mq_debug: null) {
            position: absolute;
            bottom: 110px;
            left: 350px;
            top: unset;
            max-width: 530px;
            color: $cc-white;
        } 

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            position: relative;
            left: unset;
            top: unset;
            margin-top: 30px;
            color: $cc-white;
            padding: 0 20px;
        } 
    }

    &-image {
        position: absolute;
        top: -150px;
        left: 0;
        width: 815px;
        height: 968px;

        @include mq($from: 1024px, $until: 1320px, $media-type: $mq-type, $mq_debug: null) {
            height: 550px;
        } 

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            position: relative;
            top: unset;
            left: unset;
            width: 100%;
            height: 550px;
        } 

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            position: relative;
            top: unset;
            left: unset;
            width: 100%;
            height: 320px;
        } 
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        
        &__container {
            width: 815px;
            height: 968px;

            @include mq($from: 1024px, $until: 1320px, $media-type: $mq-type, $mq_debug: null) {
                height: 550px;
                width: 200px;
            } 

            @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                width: 100%;
                height: 550px;
                margin-bottom: 70px;
            } 
            
            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                width: 100%;
                height: 320px;
                margin-bottom: 70px;
            } 
        }

        &__content {
            position: absolute;
            top: 0;
            right: 0;
            background-color: $cc-gold;
            padding: 48px 100px;
            color: $white;
            font-family: $font-arboria;
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            text-align: center;

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                padding: 10px 20px;
                width: 100%;
            } 

            h1 {
                color: $white;
                margin-bottom: 0;
                font-family: $font-arboria;
                font-size: 30px;
                font-weight: 700;
                line-height: 25px;
            }

            p {
                margin-bottom: 0;
            }
        }

    }

    &-content {
        margin-left: 420px;


        @include mq($from: 1024px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 200px;
        }

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 20px;
        }

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 20px;
            margin-right: 20px;
        } 

        &__title {
            font-family: $font-meno-banner;
            font-weight: 400;
            font-size: 120px;
            color: $cc-gold;
            line-height: 100px;
            margin-bottom: 70px;

            @include mq($from: 1024px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 100px;
                line-height: 90px;
            }

            @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 90px;
                line-height: 85px;
            }

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 55px;
                line-height: 47px;
            }  
        }

        &__text {
            font-family: $font-arboria;
            color: $cc-white;
            font-size: 18px;
            line-height: 30px;
            font-weight: 400;
            max-width: 680px;
            margin-left: 280px;


            @include mq($from: 1024px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
                margin-left: 100px;
                margin-right: 50px;
            }

            @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                margin-left: 70px;
                margin-right: 50px;
            }

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                margin-left: 0;
            } 
        }

        &__cta {
            margin-top: 60px;
        }
    }
}