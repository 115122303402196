.Quote {
    background-color: $white;
    padding-top: 240px;
    padding-bottom: 275px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        padding: 100px 20px 110px;
    }

    &-icon {

    }

    &-line {
        width: 1px;
        height: 100px;
        background-color: $cc-green;
        margin: 50px auto 50px;

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            height: 50px;
        } 
    }

    &-content {

        &__info {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }

        &__title {
            font-family: $font-meno-banner;
            font-weight: 400;
            font-size: 85px;
            line-height: 100px;
            text-align: center;
            max-width: 1000px;
            margin-bottom: 55px;
            color: $cc-green;

            @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 50px;
                line-height: 65px;
            }

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 35px;
                line-height: 45px;
            } 
        }

        &__author {
            font-family: $font-arboria;
            font-weight: 400;
            font-size: 25px;
            color: $cc-green;
            margin-bottom: 0;

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 16px;
            } 

            &:first-child {
                margin-right: 5px;
            }
        }
    }
}