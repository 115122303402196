.Footer {
    padding-top: 140px;
    padding-bottom: 70px;

    @include mq($until: 840px, $media-type: $mq-type, $mq_debug: null) {
        padding-top: 110px;
        padding-bottom: 120px;
    } 

    &-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-top {
        display: flex;
        justify-content: center;
        margin-bottom: 120px;

        @include mq($until: 840px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 60px;

            svg {
                width: 147px;
                height: auto;
            }
        } 
    }

    &-bottom {
        display: flex;

        @include mq($until: 840px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
        } 

        p, a {
            margin-bottom: 0;
            text-decoration: none;
            color: $cc-gold;
            font-family: $font-arboria;
            font-weight: 400;
            font-size: 20px;
        }

        p {
            margin-right: 120px;

            @include mq($until: 840px, $media-type: $mq-type, $mq_debug: null) {
                margin-right: 0;
                text-align: center;
            } 

            &:last-child {
                margin-right: 0;
            }
        }

        a {
            &:hover, &:focus {
                color: $cc-white;
            }
        }
    }
}