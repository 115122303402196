.LogoBlock {
    position: relative;
    background-color: $white;
    padding-top: 500px;
    padding-bottom: 265px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
        padding-top: 350px;
    }

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        padding-top: 260px;
        padding-bottom: 125px;
    } 

    &-logo {
        position: absolute;
        top: 0;
        left: -100px;

        svg {
            opacity: .2;
        }

        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            svg {
                height: auto;
                width: 430px;
            }
         }
    
        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
           left: unset;
           top: unset;
           right: -44px;
           bottom: 60px;

           svg {
               width: 131px;
               height: 217px;
           }
        } 
    }

    &-content {
        margin-left: 190px;
        max-width: 950px;

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 0;
            padding: 0 20px;
        } 

        h1 {
            margin-bottom: 35px;
            line-height: 20px;
            font-family: $font-meno-banner;
            font-size: 120px;
            font-weight: 400;
            color: $cc-grey;
            line-height: 100px;

            @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 90px;
                line-height: 90px;
            }

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 55px;
                line-height: 47px;

            } 
        
            p {
                font-family: $font-meno-banner;
                font-size: 120px;
                font-weight: 400;
                color: $cc-grey;
                line-height: 100px;
                margin-bottom: 0;

                @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
                    font-size: 90px;
                    line-height: 90px;
                }

                @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                    font-size: 55px;
                    line-height: 47px;

                } 
            }
        }

        div {
            max-width: 810px;
            font-family: $font-arboria;
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 60px;

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                margin-bottom: 40px;
            } 
        }

        &__cta {
            display: flex;

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                flex-direction: column;
                position: relative;
                z-index: 10;
            } 

            .Button--primary {

                @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                    background-color: $white;
                } 

                &:nth-child(n+2) {
                    margin-left: 30px;

                    @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                        margin-left: 0;
                        margin-top: 7px;
                    } 
                }
            }
        }
    }
}

.LocationPage {
    .LogoBlock {
        @include mq($until: 1440px, $media-type: $mq-type, $mq_debug: null) {
            padding-top: 210px;
            padding-bottom: 125px;
        }
    } 
}