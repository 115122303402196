.WestProjects {
    position: relative;
    background-color: #B0B7B6;
    padding: 180px 0 310px;
    max-height: 650px;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 180px 0 140px;
    } 

    @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 100px 0 120px;
    } 

    @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0px 0 50px;
        max-height: 430px;
    } 

    &-image {
        position: absolute;
        top: -130px;
        width: 960px;
        height: 460px;
        background-color: $white;

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            width: 760px;
        } 

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            width: 100%;
            height: 320px;
        } 

        &__container {
            width: 960px;
            height: 460px;

            @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                width: 760px;
                margin: 0 auto;
            } 

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                width: 100%;
                height: 320px;
            } 
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                object-fit: contain;
            } 
        }
    }

    &-text {
        margin-left: 85px;
        max-width: 330px;
        margin-top: 20px;

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 50px;
        } 

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 20px;
            margin-right: 20px;
        } 

        p {
            color: $white;
            font-family: $font-arboria;
            font-weight: 700;
            line-height: 30px;
            font-size: 25px;
            margin-bottom: 0;

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 20px;
            } 
        }
    }
}