.Popup {
    display: none !important;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba($color: $cc-green, $alpha: .40);
    width: 100vw;
    height: 100vh;
    z-index: 999;

    
    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        overflow-y: scroll;
    } 

    justify-content: center;
    align-items: center;

    &-content {
        position: relative;
        padding-bottom: 70px;
        overflow: hidden;

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 250px;
        } 
    }

    &-active {
        display: flex !important;
    }

    &-close {
        display: flex;
        justify-content: flex-end;
        padding: 30px 30px 0 0;
        background-color: $white;

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            padding: 30px 20px 0;
            margin-left: 20px;
            margin-right: 20px;
        } 

        svg {
            transition: transform .3s ease;
            &:hover {
                cursor: pointer;
                transform: rotate(180deg);
                transform-origin: center;
            }
        }
    }

    &-ref {
        position: absolute;
        z-index: 100;
        top: 40px;
        left: -35px;
        font-family: meno-banner, serif;
        font-weight: 400;
        font-size: 120px;
        color: #E1E1E4;
        line-height: 100px;


        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 110px;
            line-height: 90px;
        }

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 90px;
            line-height: 85px;
        }

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 55px;
            line-height: 47px;
            left: 19px;
        } 
    }

    .Form-content {
        margin-top: 0;
    }

    .Form-container {
        background-color: transparent;
        height: unset;
        max-height: unset;
    }

    .Form-item--action {
        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            bottom: -80px;
        } 
    }
}
