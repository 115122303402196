.Form {
    position: relative;

    &-container {
        background-color: #B0B7B6;
        max-height: 760px;
        height: 760px;
        display: flex;
        justify-content: center; 
    }
    
    &-content {
        position: relative;
        background-color: $white;
        padding: 100px 140px 100px;
        margin-top: -130px;
        max-width: 1360px;
        max-height: 660px;

        @include mq($from: 801px, $until: 1361px, $media-type: $mq-type, $mq_debug: null) {
            padding: 100px 50px 100px;
        } 

        @include mq($until: 800px, $media-type: $mq-type, $mq_debug: null) {
            margin: -150px 20px 0;
            padding: 50px 20px;
            max-height: 840px;
        } 
    }

    &-grid {
        display: grid;
        grid-template-columns: repeat(2, 536px);
        grid-gap: 15px;

        @include mq($from: 801px, $until: 1361px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: repeat(2, 1fr);
        } 

        @include mq($until: 800px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 1fr;
            grid-gap: 0px;
        } 

        &--3 {
            grid-template-columns: 536px repeat(2, 260px);
            grid-gap: 15px;

            @include mq($from: 801px, $until: 1361px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: repeat(3, 1fr);
            } 
    

            @include mq($until: 800px, $media-type: $mq-type, $mq_debug: null) {
                grid-template-columns: 1fr;
            } 
        }

        &--4, &--5 {
            grid-template-columns: 1fr;
        }

        &--5 {
            .Form-controls {
                
                .Form-field {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    
                    border: 1px solid #B4B4B4;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    margin: 0 30px 0 0;
                    padding: 10px 10px;
                    
                    &:checked {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        background-color: #B0B7B6 !important;
                        -webkit-box-shadow: inset 0px 0px 0px 3px #ffffff;
                        box-shadow: inset 0px 0px 0px 3px #ffffff;
                    }
                
                }
                
                .Form-label {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    position: relative;
                    top: unset;
                    left: unset;
                    pointer-events: unset;
                    font-family: $font-arboria;

                    a {
                        color: #B4B4B4;
                        font-weight: 700;
                        margin-left: 5px;
                        font-family: $font-arboria;

                        &:hover {
                            color: $cc-green;
                        }
                    }
                
                }
            }
        }
    }
    
    &-item--action {
        position: absolute;
        bottom: -167px;
        right: 0;
        z-index: 50;

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            bottom: -198px;
        } 

        @include mq($from: 344px, $until: 767px, $media-type: $mq-type, $mq_debug: null) {
            bottom: -160px;
        } 

        @include mq($until: 433px, $media-type: $mq-type, $mq_debug: null) {
            bottom: -132px;
        } 

        .Form-submit {
            width: 138px;
            height: 138px;
            border: $cc-gold 1px solid;
            border-radius: 50%;
            transition: background-color .3s ease;

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                width: 60px;
                height: 60px;

                svg {
                    width: 10px;
                    height: auto;
                }
            } 

            svg {
                path, line {
                    transition: stroke .3s ease;
                }
            }

            &:hover {
                background-color: $cc-gold;
                cursor: pointer;

                svg {
                    path, line {
                        stroke: $white;
                    }
                }
            }
        }
    }

    &-controls {
        width: 100%;
    }

    &-label {
        position: absolute;
        z-index: 888;
        top: 7px;
        left: 0px;
        text-transform: none;
        background-color: transparent;
        font-size: 18px;
        color: #c7c7c7;
        padding: 5px 0px;
        transition: all .3s ease;
        pointer-events: none;
        font-family: $font-arboria;
    }
    
    .Form-field:focus ~ .Form-label,
    .Form-field:not(:focus):valid ~ .Form-label {
        opacity: 1;
        top: -13px;
        font-size: 14px;
    }

    .ff-form-errors, .ff-errors {
        color: $red;
    }
    
    &-field, &--input {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 100%;
        padding: 15px 20px 15px 0px;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 1px solid rgba($color: $cc-green, $alpha: .35);
        margin-bottom: 50px;
        font-size: 18px;
        color: $cc-green;
        font-family: $font-arboria;

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 20px;
        } 

        &:active, &:focus {
            color: $cc-green;
        }

        &::placeholder {
            display: none !important;
            opacity: 0 !important;
            visibility:  hidden !important;
            font-family: $font-arboria;
        }
    }
}

.SubscribePage, .OfferPage {
    .Form {

        &-content {
            max-height: 552px;

            @include mq($until: 800px, $media-type: $mq-type, $mq_debug: null) {
                max-height: inherit;
                padding: 50px 20px;
                width: 100%;
            } 
        }

        &-grid {    
            &--4 {
                grid-template-columns: 1fr;
            }
    
            &--4 {
                .Form-controls {
                    
                    .Form-field {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        
                        border: 1px solid #B4B4B4;
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        margin: 0 30px 0 0;
                        padding: 10px 10px;
                        
                        &:checked {
                            -webkit-appearance: none;
                            -moz-appearance: none;
                            appearance: none;
                            background-color: #B0B7B6 !important;
                            -webkit-box-shadow: inset 0px 0px 0px 3px #ffffff;
                            box-shadow: inset 0px 0px 0px 3px #ffffff;
                        }
                    
                    }
                    
                    .Form-label {
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        position: relative;
                        top: unset;
                        left: unset;
                        pointer-events: unset;
    
                        a {
                            color: #B4B4B4;
                            font-weight: 700;
                            margin-left: 5px;
    
                            &:hover {
                                color: $cc-green;
                            }
                        }
                    
                    }
                }
            }
        }
    }   
}