.Text {
    max-width: 960px;
    margin: 160px auto;
    color: $cc-white;

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        padding: 0 20px;
        margin: 70px auto;
    } 

    h1 {
        font-family: $font-meno-banner;
        font-size: 120px;
        font-weight: 400;
        line-height: 100px;
        color: $cc-white;
        margin-bottom: 80px;

        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 110px;
            line-height: 90px;
        }

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 90px;
            line-height: 85px;
            margin-bottom: 60px;
        }

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 55px;
            line-height: 47px;
            margin-bottom: 60px;
        } 
    }

    h2 {
        color: $cc-white;
        font-family: $font-arboria;
        font-weight: 700;
        margin-bottom: 50px;

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 30px;;
        } 
    
    }

    p {
        color: $cc-white;
        font-family: $font-arboria;
        font-weight: 400;

        a {
            color: $cc-gold;

            &:hover {
                color: $cc-white;
            }
        }
    }
}