.Maps {
    height: 970px;
    width: 910px;

    @include mq($from: 769px, $until: 1024px, $media-type: $mq-type, $mq_debug: null) {
        width: 100%;
        height: 550px;
     } 

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
       width: 100%;
       height: 320px;
    }
}