.CTA {
    background-color: #B0B7B6;
    padding: 260px 0 575px;

    @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
        padding: 260px 0 350px;
    }

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        padding: 130px 0 270px;
    } 

    &-container {
        max-width: 1360px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        @include mq($from: 1281px, $until: 1620px, $media-type: $mq-type, $mq_debug: null) {
            max-width: 1024px;
         }

        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            max-width: 850px;
        }

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0 20px;
        } 
    }

    &-title {

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 60px;
        } 

        p {
            margin-bottom: 0;
            color: $cc-green;
            font-family: $font-meno-banner;
            font-weight: 400;
            font-size: 120px;
            line-height: 100px;

            strong {
                font-weight: 400;
                font-style: normal;
                margin-left: 180px;

                @include mq($from: 768px, $until: 1024px, $media-type: $mq-type, $mq_debug: null) {
                    margin-left: 50px;
                }

                @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                    margin-left: 0px;
                } 
            }

            @include mq($from: 768px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 90px;
                line-height: 70px;
            }

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 55px;
                line-height: 47px;
            } 
        }
    }

    &-cta {
        
        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin: 0 0 0 auto;
        } 
    }

    .Button--primary {
        border-color: $white;
        color: $white;

        svg {
            path, line {
                stroke: $white
            }
        }
    }
}