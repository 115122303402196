.TextMedia {
    padding-bottom: 290px;
    display: flex;
    justify-content: center;

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        flex-direction: column-reverse;
        justify-content: flex-start;
        padding-bottom: 0px;
    }

    &-content {
        position: relative;
        z-index: 10;
        background-color: $white;
        padding: 115px 120px;
        margin-right: -120px;
        max-width: 815px;
        height: max-content;

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-right: 0;
            padding: 100px 20px 95px 20px;
        } 

        &__title {
            font-family: $font-meno-banner;
            font-size: 45px;
            font-weight: 400;
            line-height: 50px;
            margin-top: 10px;
            margin-bottom: 80px;

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 30px;
                margin-bottom: 30px;
                line-height: 40px;
            } 
        }

        &__text {
            font-family: $font-arboria;
            color: #1D332F;
            font-size: 18px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 50px;
        }

        &__date {
            font-family: $font-arboria;
            color: $cc-gold;
            font-size: 25px;
            font-weight: 700;

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 20px;
            } 
        }

        &__cta {
            text-decoration: none;
            color: $cc-gold;
            font-family: $font-arboria;

            span {
                margin-left: 10px;
            }

            svg {
                line, path {
                    transition: stroke .3s ease;
                }
            }

            &:hover {
                color: $cc-green;

                svg {
                    line, path {
                        stroke: $cc-green;
                    }
                }
            }
        }
    }

    &-image {
        position: relative;
        z-index: 1;
        width: 950px;
        height: 600px;
        margin-top: 180px;

        @include mq($from: 1281px, $until: 1620px, $media-type: $mq-type, $mq_debug: null) {
            width: 720px;
            height: 600px;
            margin-top: -100px;
        }
        
        @include mq($from: 768px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            height: 452px;
            margin-left: 70px;
            margin-top: -10px;
        }

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            width: 100%;
            height: 352px;
            margin-top: 0;
        } 

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

}