.Table {
    background-color: $white;
    padding-top: 270px;
    padding-bottom: 380px;

    @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        padding-top: 120px;
        padding-bottom: 180px;
    }

    @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
        padding-top: 60px;
        padding-bottom: 90px;
    }

    &_link {
        color: $cc-green;
        text-decoration: underline;
        transition: color .3s ease;

        &:hover {
            color: $cc-gold;
            cursor: pointer;
        }
    }

    &-container {
        max-width: 1360px;
        margin: 0 auto;

        @include mq($until: 1440px, $media-type: $mq-type, $mq_debug: null) {
            padding: 0 20px;
        }
    }

    &__column {
        width: 100%;
        border-bottom: 1px solid #E1E1E4;
        line-height: 1;
        padding-top: 30px;
        padding-bottom: 30px;
        font-family: $font-arboria;
        font-weight: 400;
        font-size: 20px;
        text-align: center;

        &:first-child {
            text-align: left;
        }

        &:last-child {
            text-align: right;
        }

        @include mq($until: 960px, $media-type: $mq-type, $mq_debug: null) {
            border-bottom: none;
            text-align: left;
            padding-top: 10px;
            padding-bottom: 10px;
            display: grid;
            grid-template-columns: 200px 150px;
    
            &:last-child {
                text-align: left;
            }
        }

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            font-size: 16px;
        }

        @include mq($until: 350px, $media-type: $mq-type, $mq_debug: null) {
            grid-template-columns: 130px 110px;
        }
    }

    &__heading {
        padding-bottom: 15px;
        font-family: $font-arboria;
        font-weight: 700;
        font-size: 20px;

        &-mobile {
            display: none;

            @include mq($until: 960px, $media-type: $mq-type, $mq_debug: null) {
                display: block;
                color: $cc-gold;
                font-weight: 700;
            }

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 16px;
            }
        }
    }
    
    &-row {

        &__heading {
            display: flex;
            color: $cc-gold;

            @include mq($until: 960px, $media-type: $mq-type, $mq_debug: null) {
                display: none;
            }
        }

        &__data {
            display: flex;

            @include mq($until: 960px, $media-type: $mq-type, $mq_debug: null) {
                display: flex;
                flex-direction: column;
                border-bottom: 1px solid #E1E1E4;
                padding: 30px 0;
                font-size: 16px;
            }

            &.Table-sold {
                color: #8a8a8a !important;

                &__color {
                    color: $cc-gold;
                    font-weight: 700;
                }

                &__line {
                    text-decoration: line-through;
                }
            }
        }
    }

    .Table-sold {
        &__color {
            color: $cc-gold;
            font-weight: 700;
        }

        &__line {
            text-decoration: line-through;
        }
    }

}