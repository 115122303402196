.MediaText {
    position: relative;
    padding-bottom: 260px;

    @include mq($until: 1280px, $media-type: $mq-type, $mq_debug: null) {
        padding-bottom: 180px;
    } 
    
    &-wrapper {
        display: flex;
        justify-content: center;
        margin-top: -130px;

        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 0;
        }

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 0;
            flex-direction: column;
        } 

    }
    &-container {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }

    &-extra {
        background-color: $cc-gold;
        color: $white;
        max-width: 415px;
        padding: 20px 100px;
        text-align: center;
        margin-right: 120px;

        @include mq($until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            padding: 20px 40px;
            margin-right: 0px;
        } 

        h1 {
            color: $white;
            font-size: 30px;
            font-weight: 700;
            font-family: $font-arboria;

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 20px;
            } 
        }

        p {
            margin-bottom: 0;
        }

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            position: absolute;
            bottom: 135px;
            left: 0;
            z-index: 50;
            width: calc(100% - 30px);
        } 
    }

    .TextMedia-image {
        margin-top: -220px;

        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: -150px;
            width: 620px;
            margin-left: 0;
        }

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: -175px;
            padding-left: 30px;
            margin-right: -30px;
            z-index: 50;
            width: 375px;
        } 
    }

    .TextMedia-content {
        margin-right: 0;
        margin-left: -120px;

        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: auto;
            margin-right: auto;
        }

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: -277px;
            margin-left: 0;
        }

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 0;
            margin-top: -177px;
            padding: 250px 20px 145px 20px;
        } 
    }
}