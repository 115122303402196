/* Navigation */
.Navigation {
    display: flex;
    justify-content: center;
    height: 100%;

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        flex-direction: row-reverse;
        align-items: center;
    } 

    &-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
        } 
    }

    &-button {
        display: none;
    }

    &-right, &-left {
        nav {
            ul {
                display: flex;
                align-items: center;
                padding-left: 0;
                margin-bottom: 0;

                @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                    flex-direction: column;
                } 

                li {
                    list-style: none;
                    margin-bottom: 0;
                    
                    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                        text-align: center;
                    } 

                    a {
                        text-decoration: none;
                        color: $cc-gold;
                        font-family: $font-arboria;
                        font-weight: 400;
                        font-size: 20px;
                        text-transform: lowercase;

                        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                            color: $cc-white;
                            font-family: $font-meno-banner;
                            font-weight: 400;
                            font-size: 55px;
                        } 

                        &:hover {
                            color: $cc-white;

                            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                                color: $white;
                                font-family: $font-meno-banner;
                                font-style: italic;
                                font-weight: 300;
                            } 
                        }

                        &.is-active {
                            color: $cc-white;
                            font-weight: 700;

                            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                                color: $cc-gold;
                                font-family: $font-meno-banner;
                                font-style: italic;
                                font-weight: 300;
                            } 
                        }
                    }
                }
            }
        }
    }

    &-right {
        nav {
            ul {
                li {
                    margin-left: 130px;
                    white-space: nowrap;

                    a {
                        white-space: nowrap;
                    }

                    @include mq($from: 1550px, $until: 1780px, $media-type: $mq-type, $mq_debug: null) {
                        margin-left: 100px;
                    } 

                    @include mq($from: 1024px, $until: 1549px, $media-type: $mq-type, $mq_debug: null) {
                        margin-left: 80px;
                    } 

                    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                        margin-left: 0;
                    } 

                    &.Nav__button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        
                        @include mq($from: 1024px, $until: 1410px, $media-type: $mq-type, $mq_debug: null) {
                            width: 64px;

                            a {
                                position: absolute;
                                right: 0;
                                color: transparent;
                                font-size: 0;
                                padding: 15px 23px;
                                transition: all .3s ease;

                                svg {
                                    margin-top: 5px;
                                    margin-left: 0;
                                    transition: all .3s ease;
                                }
                            }

                            &:hover {
                                a {
                                    color: $cc-white;
                                    font-size: 20px;
                                    padding: 15px 23px;
                                    
                                    svg {
                                        margin-top: 0;
                                        margin-left: 20px;
                                    }
                                }
                            }
                        }
                        
                        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                            margin-top: 30px;
                            
                            a {
                                color: $cc-gold;
                                padding: 15px 23px;
                                font-size: 20px;
                                font-family: $font-arboria;
                            }
                        } 

                    }
                }
            }
        }
    }

    &-left {
        nav {
            ul {
                li {
                    margin-right: 130px;

                    @include mq($from: 1550px, $until: 1780px, $media-type: $mq-type, $mq_debug: null) {
                        margin-right: 100px;
                    } 

                    @include mq($from: 1024px, $until: 1549px, $media-type: $mq-type, $mq_debug: null) {
                        margin-right: 80px;
                    } 

                    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                        margin-right: 0;
                    } 

                    &.Nav__button {
                        width: 260px;

                        @include mq($from: 1024px, $until: 1410px, $media-type: $mq-type, $mq_debug: null) {
                            width: 64px
                        }

                    }
                }
            }
        }
    }

    #menu-button {
        position: absolute;
        right: 35px;
        z-index: 5;
        cursor: pointer;
        user-select: none;
        height: 14px;

        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;

        @include mq($from: 1024px, $media-type: $mq-type, $mq_debug: null) {
            display: none !important;
        } 

        span {
            display: block;
            position: relative;
            height: 2px;
            width: 30px;
            border-radius: 2px;
            opacity: 1;
            background-color: $cc-gold;

            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: .3s ease-in-out;
            -moz-transition: .3s ease-in-out;
            -o-transition: .3s ease-in-out;
            transition: .3s ease-in-out;

            &:nth-child(1) {
                top: 1px;
            }

            &:nth-child(2) {
                top: 9px;
            }
        }
    }


    &-button:checked ~ #menu-button {
        
        span {
            background-color: $cc-gold;
            
            &:nth-child(1) {
                top: 9px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
            }

            &:nth-child(2) {
                top: 7px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
            }
        }
    }

    &-button:checked ~ .Navigation-body {
        transform: translateX(0);
        transition: all .2s ease-in-out;
        opacity: 1 !important;
        pointer-events: all;
    }

    &-button:checked ~ .Logo {
        svg {
            path {
                transition: .2s fill ease;
                // fill: $white;
            }
        } 
    }

    &-body {
        z-index: 2;
        display: flex; 
        
        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            position: fixed;
            top: 0;
            right: 0;
            min-height: 100vh;
            height: -webkit-fill-available;
            width: 100vw;
            background-color: $cc-green;
            overflow-x: scroll;
            -webkit-overflow-scrolling: touch;
            transform: translateX(100vw);
            transition: all .3s ease-in-out;
            padding-top: 70px;
            padding-bottom: 50px;
            flex-direction: column;
            justify-content: center;
        } 
    }
}
