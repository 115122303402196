/* Paragraph */
p {
    font-family: $font-arboria;
    margin-bottom: u($spacing-unit);

    @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
        font-size: 16px;
        line-height: 25px;
    }

    a {
        font-family: $font-arboria;
    }
}
