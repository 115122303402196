.Intro {
    position: relative;
    z-index: 50;
    padding-bottom: 250px;
    margin-bottom: -400px;

    @include mq($from: 1921px, $media-type: $mq-type, $mq_debug: null) {
        max-width: 1920px;
        margin: 0 auto -400px;
    } 

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        padding: 0;
        margin-bottom: -166px;
    } 


    &-title {
        padding-left: 260px;
        margin-top: -70px;
        transform: translateY(-550px);

        @include mq($from: 1401px, $until: 1620px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 220px;
        }

        @include mq($from: 1281px, $until: 1400px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 200px;
        }

        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
           padding-left: 160px;
           margin-top: -45px;
        }

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 20px;
            padding-right: 20px;
            margin-top: -55px;
        }

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 20px;
            padding-right: 20px;
            margin-top: -35px;
            transform: translateY(-250px);
        } 

        &.addColor {
            strong {
                color: $cc-gold;
            }
        }

        p {
            font-family: $font-meno-banner;
            font-size: 120px;
            font-weight: 400;
            line-height: 100px;
            color: $cc-white;

            @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 110px;
                line-height: 90px;
            }

            @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 90px;
                line-height: 85px;
            }

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 55px;
                line-height: 47px;
            } 

            strong {
                font-style: normal;
                font-weight: 400;
                color: $cc-white;
                transition: color .3s ease;
            }
        }
    }

    &-content {
        padding-left: 260px;
        display: flex;
        align-items: flex-end;
        margin-top: 80px;
        margin-bottom: 280px;

        @include mq($from: 1401px, $until: 1620px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 220px;
        }

        @include mq($from: 1281px, $until: 1400px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 200px;
        }

        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 20px;
            margin-bottom: 150px;
        }

        @include mq($from: 920px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: unset;
            align-items: flex-end;
            margin-top: 50px;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 0;
        }

        @include mq($until: 919px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column-reverse;
            align-items: flex-start;
            margin-top: 0;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: 0;
        } 
    }

    &-cta {

    }

    &-text {
        margin-left: 300px;
        font-family: $font-arboria;
        font-weight: 400;
        font-size: 18px;
        color: $cc-white;
        max-width: 540px;

        @include mq($from: 1401px, $until: 1620px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 200px;
        }

        @include mq($from: 1281px, $until: 1400px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 100px;
         }

        @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
            padding-left: 20px;
            margin-left: 100px;
            max-width: 450px;
        }

        @include mq($from: 920px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            max-width: 400px;
            margin-bottom: 0;
            margin-left: 70px;
        }

        @include mq($until: 919px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 0;
            margin-bottom: 40px;
        } 

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &-images {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: max-content;

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 120px;
            flex-direction: column;
            align-items: flex-start;
        } 

        &__item {
            height: auto;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }

            &:nth-child(1) {
                width: 620px;

                @include mq($from: 1024px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
                    width: 460px;
                }

                @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                    width: 460px;
                    margin-left: 0;
                    margin-bottom: 55px;
                }

                @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                    margin-left: 0;
                    width: 338px;
                    margin-bottom: 54px;
                } 
            }

            &:nth-child(2) {
                width: 535px;
                margin-left: 70px;

                @include mq($from: 1024px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
                    margin-left: 35px;
                    width: 370px;
                }

                @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                    width: 370px;
                    margin-left: 0;
                    margin: 0 0 0 auto;
                }

                @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                    margin-left: 0;
                    width: 260px;
                    margin: 0 0 0 auto;
                } 
            }

            &:nth-child(3) {
                width: 535px;
                margin-left: 70px;

                @include mq($from: 1024px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
                    margin-left: 35px;
                    width: 370px;
                }

                @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                    display: none;
                } 
            }
        }
    }
}