/* Settings: Fonts */

// Default
$font-sans:                             "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-serif:                            Georgia, "Times New Roman", Times, serif !default;
$font-code:                             Menlo, Monaco, Consolas, monospace, serif !default;

// Custom
$font-meno-banner:                      meno-banner, serif !default; //200, 300, 400, 500, 600, 700, 800, 900
$font-arboria:                      arboria, sans-serif !default; //200, 300, 400, 500, 600, 700, 800, 900


// Meno Banner Regular
// font-family: meno-banner, serif;
// font-weight: 400;
// font-style: normal;

// Meno Banner Italic
// font-family: meno-banner, serif;
// font-weight: 400;
// font-style: italic;

// Meno Banner Bold Italic
// font-family: meno-banner, serif;
// font-weight: 700;
// font-style: italic;

// Meno Banner Bold
// font-family: meno-banner, serif;
// font-weight: 700;
// font-style: normal;

// Meno Banner Extra Condensed Italic
// font-family: meno-banner-extra-condensed, serif;
// font-weight: 400;
// font-style: italic;

// Meno Banner Extra Condensed Regular
// font-family: meno-banner-extra-condensed, serif;
// font-weight: 400;
// font-style: normal;

// Meno Banner Extra Condensed Bold
// font-family: meno-banner-extra-condensed, serif;
// font-weight: 700;
// font-style: normal;

// Meno Banner Extra Condensed Bold Italic
// font-family: meno-banner-extra-condensed, serif;
// font-weight: 700;
// font-style: italic;

// Meno Banner Condensed Regular
// font-family: meno-banner-condensed, serif;
// font-weight: 400;
// font-style: normal;

// Meno Banner Condensed Italic
// font-family: meno-banner-condensed, serif;
// font-weight: 400;
// font-style: italic;

// Meno Banner Condensed Bold Italic
// font-family: meno-banner-condensed, serif;
// font-weight: 700;
// font-style: italic;

// Meno Banner Condensed Bold
// font-family: meno-banner-condensed, serif;
// font-weight: 700;
// font-style: normal;

// Arboria Thin Italic
// font-family: arboria, sans-serif;
// font-weight: 100;
// font-style: italic;

// Arboria Thin
// font-family: arboria, sans-serif;
// font-weight: 100;
// font-style: normal;

// Arboria Light
// font-family: arboria, sans-serif;
// font-weight: 300;
// font-style: normal;

// Arboria Light Italic
// font-family: arboria, sans-serif;
// font-weight: 300;
// font-style: italic;

// Arboria Book Italic
// font-family: arboria, sans-serif;
// font-weight: 400;
// font-style: italic;

// Arboria Book
// font-family: arboria, sans-serif;
// font-weight: 400;
// font-style: normal;

// Arboria Medium
// font-family: arboria, sans-serif;
// font-weight: 500;
// font-style: normal;

// Arboria Medium Italic
// font-family: arboria, sans-serif;
// font-weight: 500;
// font-style: italic;

// Arboria Bold
// font-family: arboria, sans-serif;
// font-weight: 700;
// font-style: normal;

// Arboria Bold Italic
// font-family: arboria, sans-serif;
// font-weight: 700;
// font-style: italic;

// Arboria Black
// font-family: arboria, sans-serif;
// font-weight: 900
// font-style: normal;

// Arboria Black Italic
// font-family: arboria, sans-serif;
// font-weight: 900;
// font-style: italic;
