.Parallax {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    padding-bottom: 75vh;

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        background-size: unset;
        background-attachment: unset;
        background-position: bottom;
        background-color: $white;
        background-image: unset !important;
        padding-bottom: 0;
    } 

    &-contentBlocks {
        display: flex;
        justify-content: space-between;
        max-width: 1090px;
        padding-top: 170px;
        padding-bottom: 170px;
        margin: 0 auto;

        @include mq($until: 1150px, $media-type: $mq-type, $mq_debug: null) {
            flex-direction: column;
            justify-content: center;
            margin: 0 20px;
            padding-top: 0;
            padding-bottom: 50px;
        } 
        
        &__container {
            width: 480px;
            min-height: 650px;
            background-color: $cc-green;
            padding: 80px 70px 75px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            @include mq($from: 481px, $until: 1150px, $media-type: $mq-type, $mq_debug: null) {
                margin: 0 auto 50px;

                &:last-child {
                    margin-bottom: 0;
                }
            } 

            @include mq($until: 480px, $media-type: $mq-type, $mq_debug: null) {
                width: 100%;
                margin-bottom: 50px;
                padding: 50px 20px;
                min-height: 410px;

                &:last-child {
                    margin-bottom: 0;
                }
            } 
        }

        &__title {
            color: $cc-gold;
            font-size: 25px;
            font-family: $font-arboria;
            font-weight: 700;
            line-height: 25px;
            width: 290px;

            @include mq($until: 480px, $media-type: $mq-type, $mq_debug: null) {
                margin-bottom: 20px;
            } 
        }

        &__text {
            color: $cc-white;
            font-size: 18px;
            font-family: $font-arboria;
            font-weight: 400;
        }
    }

    &-location {
        width: 100vw;
        background-color: #B0B7B6;
        padding: 250px 0 290px 280px;

        @include mq($from: 1024px, $until: 1720px, $media-type: $mq-type, $mq_debug: null) {
            padding: 250px 0 290px 30px;
        }

        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            padding: 100px 20px 100px 20px;
        } 

        &__wrapper {
            @include mq($from: 1921px, $media-type: $mq-type, $mq_debug: null) {
                max-width: 1700px;
                margin: 0 auto 0;
            } 
        }

        &__container {
            display: flex;
            justify-content: space-between;

            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                flex-direction: column;
                justify-content: center;
            } 
            
        }

        &__image {
            display: none;
    
            @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                display: block;
                width: 100%;
                height: 230px;
    
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            } 
        }

        &__title {
            margin-bottom: 80px;

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                margin-bottom: 40px;
            } 

            p {
                font-family: $font-meno-banner;
                font-size: 120px;
                font-weight: 400;
                color: $cc-green;
                line-height: 100px;
                margin-bottom: 0;

                strong {
                    font-family: $font-meno-banner;
                    font-size: 120px;
                    font-weight: 400;
                    color: $cc-green;
                    margin-left: 185px;

                    @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
                        margin-left: 0;
                        font-size: 110px;
                        line-height: 90px;
                    }
        
                    @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                        margin-left: 0;
                        font-size: 90px;
                        line-height: 85px;
                    }
        
                    @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                        margin-left: 50px;
                        font-size: 55px;
                        line-height: 47px;
                    } 
                }

                @include mq($from: 1024px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
                    font-size: 110px;
                    line-height: 90px;
                }
    
                @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                    font-size: 90px;
                    line-height: 85px;
                }
    
                @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                    font-size: 55px;
                    line-height: 47px;
                } 
            }
        }

        &__text {
            width: 480px;
            color: $white;
            font-size: 18px;
            font-family: $font-arboria;
            font-weight: 400;
            line-height: 30px;

            @include mq($from: 1024px, $until: 1720px, $media-type: $mq-type, $mq_debug: null) {
                width: 850px;
                color: #ffffff;
                font-size: 18px;
                font-family: arboria, sans-serif;
                font-weight: 400;
                line-height: 30px;
                margin-right: 50px;
            }

            @include mq($until: 480px, $media-type: $mq-type, $mq_debug: null) {
                width: 100%;
            } 
        }
    }

    &-locations {
        margin-top: 190px;

        @include mq($from: 769px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-bottom: 70px;
        }

        @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
            margin-top: 100px;
            margin-bottom: 50px;
        } 

        &__item {
            position: relative;
            display: none;
            background-color: $white;
            padding: 85px 70px;

            &.ActiveHover {
                display: block;
            }

            @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                padding: 70px 20px;
            } 
        }

        &__icon {
            position: absolute;
            top: -69px;
            right: 50px;

            svg {
                width: 138px;
                height: 138px;

                @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
                    width: 100px;
                    height: 100px;
                } 
            }

        }

        &__title {
            color: $cc-gold;
            font-size: 25px;
            font-family: $font-arboria;
            font-weight: 700;
            line-height: 25px;
            margin-bottom: 48px;
        }

        &__text {
            color: $black;
            font-size: 18px;
            font-family: $font-arboria;
            font-weight: 400;
            line-height: 30px;
        }

        &__list {
            margin-left: 0;
            padding-left: 0;
        }

        &__link {
            display: flex;
            align-items: center;
            color: $cc-gold;
            text-decoration: none;
            transition: color .3s ease;

            &:hover {
                color: $cc-green;

                div {
                    background-color: $cc-green;
                }
            }

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 12px;
                font-weight: 700;
                color: $white;
                background-color: $cc-gold;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                margin-right: 15px;
                transition: all .3s ease;
            }
        }
    }
}