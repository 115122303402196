/* Button */

.Button {

    // Primary
    &--primary {
        text-decoration: none;
        background-color: transparant;
        border: solid 1px $cc-gold;
        color: $cc-gold;
        font-size: u(18);
        font-family: $font-arboria;
        font-weight: 400;
        line-height: 30px;
        transition: background-color .1s ease, border .1s ease,  color .1s ease;
        width: fit-content;
        padding: 15px 25px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        text-transform: lowercase;

        svg {
            margin-left: 20px;
            margin-top: -4px;
        }

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $cc-gold;
                border: solid 1px $cc-gold;
                color: $white;

                svg {
                    path,line {
                        stroke: $white;
                    }
                }
            }
        }

        &.Button--outline {
            background-color: transparent;
            border: solid 1px $cc-black;
            color: $cc-black;
            font-size: u(16);
            font-family: $font-sans;
            font-weight: 400;
            transition: border .1s ease, background-color .1s ease , color .1s ease, fill .1s ease;
            border-radius: 0;

            svg {
                path {
                    transition: fill .1s ease;
                    fill: $cc-black;
                }
            }
        
            &:active,
            &:focus,
            &:hover,
            &.is-active {
                transition: border .1s ease, background-color .1s ease, color .1s ease;
                border: solid 1px $cc-black;
                background-color: $cc-black;
                color: $white;

                svg {
                    path {
                        transition: fill .1s ease;
                        fill: $white;
                    }
                }
            }
        }
    }

    // Secondary
    &--secondary {
        position: relative;
        color: $cc-black;
        font-size: u(12);
        font-weight: 700;
        text-transform: uppercase;
        transition: color .2s ease;
        padding: 0;

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: u(2);
            background-color: $cc-black;
            transition: background-color .2s ease;
        }


        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $cc-black;

                &::after {
                    background-color: $cc-black;
                }
            }
        }

        &.Button--outline {
            position: relative;
            color: $cc-black;
            font-size: u(12);
            font-weight: 700;
            text-transform: uppercase;
            transition: color .2s ease;
            padding: 0;
            border: none;

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: u(2);
                background-color: $cc-black;
                transition: background-color .2s ease;
            }
        
            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $cc-black;

                &::after {
                    background-color: $cc-black;
                }
            }
        }
    }

    &--black {
        background-color: transparent;
        border: solid 2px $cc-black;
        border-radius: u(50);
        color: $cc-black;
        font-size: u(16);
        font-weight: 700;
        padding: 15px 20px;
        transition: background-color .1s ease, border .1s ease,  color .1s ease;
        width: max-content;

        svg {
            path {
                transition: stroke .1s ease, fill .1s ease;
                stroke: $cc-black;
                fill: $cc-black;
            }
        }

        &:active,
        &:focus,
        &:hover {
            background-color: $cc-black;
            border: solid 2px $cc-black;
            color: $white;

            svg {
                path, line {
                    stroke: $white;
                    fill: $white;
                }
            }
        }
        
    }

    &--line {
        position: relative;
        color: $cc-black;
        font-family: $font-sans;
        font-size: 20px;
        font-weight: 400;
        color: $cc-black;
        padding-bottom: 3px;
        
        &::after {
            position: absolute;
            content: '';
            width: 100%;
            height: 1px;
            background-color: $cc-black;
            bottom: 0;
            right: 0;
            transition: .3s ease;
        }

        &:active,
        &:focus,
        &:hover {
            color: $cc-darkGrey;

            &::after {
                content: '';
                width: 0%;
            }
        }
    }

}