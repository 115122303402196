.LargeImage {
    width: 100vw;
    height: 735px;
    overflow: hidden;

    @include mq($until: 768px, $media-type: $mq-type, $mq_debug: null) {
        height: 430px;
    } 

    figure {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}