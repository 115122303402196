.Banner {
    position: relative;
    height: 1030px;
    width: 100vw;
    display: flex;
    align-items: center;

    // @include mq($from: 1921px, $media-type: $mq-type, $mq_debug: null) {
    //     max-width: 1920px;
    //     margin: 0 auto;
    // } 

    @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
        height: calc(100vh - 90px);
        flex-direction: column;
        align-items: flex-start;
    } 
    
    &-title {
        margin-left: 280px;
        max-width: 1200px;

        @include mq($from: 1361px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 150px;
        } 

        @include mq($from: 1024px, $until: 1360px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 100px;
        } 

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 70px;
            margin-bottom: 50px;
        } 

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 20px;
            margin-right: 20px;
        } 
        
        p {
            margin-bottom: 0;
            color: $cc-white;
            font-family: $font-meno-banner;
            font-weight: 400;
            font-size: 150px;
            line-height: 130px;
            hyphens: auto;
            word-break: break-word;

            @include mq($from: 1024px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 110px;
                line-height: 90px;
            }

            @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 90px;
                line-height: 85px;
            }

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 55px;
                line-height: 47px;
            } 
        }
    }

    &-info {
        margin-left: 140px;

        @include mq($from: 1024px, $until: 1360px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 50px;
        } 

        @include mq($from: 768px, $until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 70px;
        } 

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            margin-left: 0;
            padding: 0 20px;
        } 

        h2 {
            color: $cc-gold;
            font-size: 25px;
            font-weight: 700;
            font-family: $font-arboria;
            margin-bottom: 65px;

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 20px;
                margin-bottom: 50px;
                margin-top: 50px;
            } 
        }

        div {
            color: $cc-white;
            font-size: 20px;
            font-weight: 400;
            font-family: $font-arboria;

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                font-size: 18px;
            } 

            a {
                text-decoration: none;
                color: $cc-white;

                &:hover, &:focus {
                    color: $cc-gold;
                }
            }
        }
    }

    &-image {
        
        &__withTitle {
            position: absolute;
            right: 0;
            top: 0;
            z-index: -1;
            height: 100%;
            width: 50%;

            @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
                width: 100%;
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background-color: rgba($color: $cc-green, $alpha: 0.2);
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__one {
            position: relative;
            width: 100vw;
            height: 100%;

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 100%;
                background-color: rgba($color: $cc-green, $alpha: 0.2);
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

    }

    &-imageBron__1, &-imageBron__2 {
        position: absolute;
        right: 10px;
        bottom: 0;
        color: $white;
        font-size: 10px;
        line-height: 30px;
        transform: rotate(90deg);
        transform-origin: center right;
    }

    &-images {

        @include mq($until: 767px, $media-type: $mq-type, $mq_debug: null) {
            width: 100%;
        }


        &__1 {
            position: absolute;
            top: 245px;
            right: 140px;
            width: 674px;
            height: 876px;
            z-index: 50;

            @include mq($from: 1441px, $until: 1720px, $media-type: $mq-type, $mq_debug: null) {
                top: 210px;
                right: 0px;
                width: 470px;
                height: auto;
            }

            @include mq($from: 1281px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
                top: 0px;
                right: 0px;
                width: 470px;
                height: 876px;
            }

            @include mq($from: 960px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
                top: 45px;
                right: 0px;
                width: 350px;
                height: auto;
            }

            @include mq($from: 580px, $until: 959px, $media-type: $mq-type, $mq_debug: null) {
                top: 45px;
                right: 0px;
                width: 290px;
                height: auto;
            }

            @include mq($until: 579px, $media-type: $mq-type, $mq_debug: null) {
                position: relative;
                top: unset;
                right: unset;
                height: 320px;
                width: 240px;
                margin-top: 50px;
                margin-bottom: 40px;
            } 

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__2 {
            position: absolute;
            left: 280px;
            bottom: -300px;
            width: 674px;
            height: 443px;
            z-index: 50;

            @include mq($from: 1441px, $until: 1720px, $media-type: $mq-type, $mq_debug: null) {
                left: 80px;
                bottom: -160px;
                width: 604px;
                height: 443px;
            }

            @include mq($from: 1281px, $until: 1440px, $media-type: $mq-type, $mq_debug: null) {
                left: 80px;
                bottom: -160px;
                width: 604px;
                height: 443px;
            }

            @include mq($from: 960px, $until: 1280px, $media-type: $mq-type, $mq_debug: null) {
                left: 0;
                bottom: -100px;
                width: 544px;
                height: auto;
            }

            @include mq($from: 580px, $until: 959px, $media-type: $mq-type, $mq_debug: null) {
                left: 0;
                bottom: -120px;
                width: 474px;
                height: auto;
            }

            @include mq($until: 579px, $media-type: $mq-type, $mq_debug: null) {
                position: relative;
                left: unset;
                bottom: unset;
                width: 300px;
                height: 320px;
                margin-left: auto;
                margin-right: 0;
                margin-bottom: -80px;
            } 

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &-scroll {
        position: absolute;
        bottom: 35px;
        left: 0;
        right: 0;
        margin: 0 auto;
        border: solid 1px $cc-gold;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        svg {
            transition: transform .3s ease;
        }

        &:hover {
            svg {
                transform: translateY(10px);
            }
        }
    }
}

.SubscribePage, .ContactPage, .AboutPage {
    
    .Banner {
        @include mq($until: 1440px, $media-type: $mq-type, $mq_debug: null) {
            height: max-content;
            padding: 50px 0 250px;
        }
    } 
}

.SubscribePage, .ContactPage {
    .Banner {
        @include mq($from: 1921px, $media-type: $mq-type, $mq_debug: null) {
            max-width: 1700px;
            margin: 0 auto 0;
        }
    }
}

.LocationPage {
    .Banner {

        @include mq($from: 1921px, $media-type: $mq-type, $mq_debug: null) {
            max-width: 1920px;
            margin: 0 auto 0;
        } 
       
        @include mq($from: 580px, $until: 959px, $media-type: $mq-type, $mq_debug: null) {
            height: 750px;
            padding-top: 50px;
        }

        @include mq($until: 579px, $media-type: $mq-type, $mq_debug: null) {
            height: max-content;
            padding: 50px 0 0px;
        }
    } 
}

.OfferPage {
    .Banner {

        @include mq($from: 1024px, $media-type: $mq-type, $mq_debug: null) {
            height: calc(100vh - 147px);
        }
       
        @include mq($until: 1023px, $media-type: $mq-type, $mq_debug: null) {
            height: calc(100vh - 90px);
            justify-content: center;
            align-items: center;
        }
    } 
}

.AboutPage {
    .Banner {
        @include mq($from: 1921px, $media-type: $mq-type, $mq_debug: null) {
            max-width: 1920px;
            margin: 0 auto 0;
        }
    } 
}